import React from "react"
import Layout from "templates/basic"
import Intro from 'components/intro'
import style from 'templates/ServicesServiceGet/ServicesServiceGet.module.scss';
import SEO from 'components/seo'
import { graphql } from 'gatsby'
import SuccessStories from 'components/successStories';
import PortfolioGrid from 'components/portfolioGrid';
import StaffGrid from 'components/staffGrid';

const ServicesServiceGet = ({ data, pageContext }) => {

	if (!data.allServicesService.edges[0]) {
		return (
			<Layout>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className={` cell small-12`}>
							<h1>Nothing to see here</h1>
							<p>The service that you are looking for has either moved or no longer exists.</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
	const service = data.allServicesService.edges[0].node;
	const serviceGroup = service.serviceGroup;
	const danosDiff = service.danosDiff;
	const teamLeads = service.Person;
	const stories = service.Story;
	const portfolios = service.Option;

	function Grid({ data }) {
		const serviceGroupArr = data.map(({ body, preTitle, title }, index) => {
			return (
				<div key={index} className="cell">
					<div className={style.innerWrapper}>
						<div className={style.preTitle}>{preTitle}</div>
						<div className={style.title}>{title}</div>
						<div className={style.body} dangerouslySetInnerHTML={{ __html: (body ? body : '') }} />
					</div>
				</div>
			);
		});
		return (serviceGroupArr);
	}
	return (
		<>
			<Layout image={service.photo} preTitle={service.preTitle} title={service.title} link={service.link} linkText={service.linkText} enableDanosFoundation={false} enableFooterTabs={true}>
				<Intro pullQuote={(service.pullQuote) ? service.pullQuote : null} headline={service.pageHeader} pageCopy={service.intro} />
				{serviceGroup.length > 0 &&
					<div className={`grid-container ${style.serviceCardWrapper}`}>
						<div className={`grid-x grid-padding-x small-up-1${(serviceGroup.length > 1) ? ' large-up-2' : ''}`}>
							<Grid data={serviceGroup} />
						</div>
					</div>
				}
				{danosDiff.length > 0 &&
					<React.Fragment>
						<div style={{ marginBottom: "99px" }} className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell small-12">
									<h2 className="text-center underline">Danos Difference</h2>
								</div>
							</div>
						</div>
						<div className={`grid-container ${style.danosDiffWrapper}`}>
							<div className={`grid-x grid-padding-x small-up-1${(danosDiff.length > 1) ? ' large-up-2' : ''}`}>
								<Grid data={danosDiff} />
							</div>
						</div>
					</React.Fragment>
				}
				{portfolios.length > 1 &&
					<section id="service_portfolio" className={style.portfolio}>
						<div style={{ marginBottom: "99px" }} className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell small-12">
									<h2 className="text-center underline">Portfolio</h2>
								</div>
							</div>
						</div>
						<PortfolioGrid data={portfolios} backgroundColor="F4F4F4" />
					</section>
				}
				{teamLeads.length > 0 &&
					<section className={style.team}>
						<div style={{ marginBottom: "99px" }} className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell small-12">
									<h2 className="text-center underline">{service.title} Team Leads</h2>
								</div>
							</div>
						</div>
						<StaffGrid closeText="Close" staff={teamLeads} />
					</section>
				}
				{stories.length > 0 &&
					<SuccessStories data={stories} />
				}
			</Layout>
			<SEO title={`${service.title} | Service`} />
		</>
	)
}

export default ServicesServiceGet

export const query = graphql`
	query ($permalink: String) {
		allServicesService(filter: {permalink: {eq: $permalink}}) {
			edges {
			  node {
				_model
				id
				title
				link
				linkText
				pdf
				permalink
				photo
				preTitle
				pullQuote
				serviceGroup
				danosDiff
				description
				featured
				intro
				icon
				pageHeader
				Person {
				  object {
					position
					photo
					permalink
					lastName
					firstName
					email
					bio
				  }
				}
				Story {
				  object {
					title
					problem
					result
					solution
				  }
				}
				Option {
				  object {
					name
					photo
					permalink
					description
					logos
				  }
				}
			  }
			}
		  }
	  site {
		siteMetadata {
		  siteUrl
		  twitterHandle
		  title
		  author
		}
	  }
	}
`