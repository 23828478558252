import React, {Component} from 'react';
import style from './arrow.module.scss';
import ReactDOM from 'react-dom';
import Icon from '../icon';

class Arrow extends Component {

	componentDidMount() {
		const node = ReactDOM.findDOMNode(this);
		const onClick = this.props.onClick;
		let direction;
		if (this.props.direction === -1 || this.props.direction === 'left')direction = -1;
		else direction = 1;
		node.addEventListener('click', function(evt){
			onClick(direction);
		});
	}

	render() {
		let arrow;
		let whichArrow;
		if (this.props.direction === -1 || this.props.direction === 'left')arrow = 'arrowLeft';
		else arrow = 'arrowRight';
		if (this.props.direction === -1 || this.props.direction === 'left')whichArrow = style.previous;
		else whichArrow = style.next;
		let className = [style.container];
		className.push(whichArrow);
		if (this.props.disabled)className.push(style.disabled);
		return (
			<div className={ className.join(' ') } ><Icon icon={arrow} title={this.props.title} className={ [style.arrowButton, 'eventArrowButton'].join(' ') } /></div>
		)
	}
}

export default Arrow;
