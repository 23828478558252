import React, { Component } from 'react'
import style from './portfolioGrid.module.scss';
import Slider from 'react-slick';
// import Card from './card';
import Card from 'components/card/'
import Arrow from './arrow';
import ReactDOM from 'react-dom';
import $ from 'jquery';

class PortfolioGrid extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			leftDisabled: true,
			rightDisabled: false,
		}
		this.node = null;
	}


	handleClick(direction) {
		if (direction === -1) this.slider.slickPrev();
		else this.slider.slickNext();
	}

	handleChange(slide) {
		const hasNext = (parseInt($(this.node).find('.slick-slide.slick-active').last().data('index')) < $(this.node).find('.slick-slide').length - 1 ? true : false);
		this.setState({
			leftDisabled: (slide === 0),
			rightDisabled: !hasNext,
		});
	}

	componentDidMount() {
		this.node = ReactDOM.findDOMNode(this);
	}

	render() {
		const slidesNumber = this.props.slides || 3;
		const slick = {
			dots: false,
			infinite: false,
			slidesToShow: 3,
			slidesToScroll: 3,
			className: style.slick,
			rows: 2,
			arrows: false,
			ref: slider => (this.slider = slider),
			afterChange: this.handleChange,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2.5,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1.17,
						slidesToScroll: 1,
						rows: 1,
					}
				},
			],
		}
		const leftDisabled = this.state.leftDisabled;
		const rightDisabled = this.state.rightDisabled;
		const color = this.props.backgroundColor || 'ffffff';
		const backgroundColor = {
			backgroundColor: '#' + color,
		}
		const portfolios = this.props.data;
		return (
			<section className={style.portfolio} style={backgroundColor} >
				<div className={`${style.container} grid-container`}>
					<div className="grid-x grid-margin-x" style={{ alignItems: "center" }}>
						{portfolios.length > slidesNumber && <div className={`${style.arrows} cell show-for-medium`}>
							<Arrow direction="left" title="Go to previous slide of portfolio items." onClick={this.handleClick} disabled={leftDisabled} />
							<Arrow direction="right" title="Go to next slide of portfolio items." onClick={this.handleClick} disabled={rightDisabled} />
						</div>}
					</div>
					<div className="cell small-12">
						<Slider {...slick}>
							{portfolios.map((portfolio, index) => (
								<Card key={index} className="minHeight" data={portfolio} type="Portfolio" />
							))}
						</Slider>
					</div>
				</div>
			</section>
		)
	}
}

export default PortfolioGrid;